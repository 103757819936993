<template>
  <div class="courses-details">
    <b-overlay
        :show="show"
        rounded="sm"
    >
      <div class="d-flex justify-content-center" style="min-height: 500px; height: 500px">
        <div class="align-self-center text-center">
          <h4 class="mb-5">Для участия в вебинаре зарегисрируйтесь или войдите под своим логином и паролем.</h4>
          <b-button variant="info" class="mr-1" :to="{ name: 'login', query: { redirectTo }}">Вход</b-button>
          <b-button variant="primary" :to="{ name: 'register', query: { redirectTo }}">Регистрация</b-button>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import { BCard, BButton, BLink, BOverlay } from 'bootstrap-vue'
import router from '@/router'

export default {
  components: {
    BCard,
    BButton,
    BLink,
    BOverlay,
  },
  data() {
    return {
      userInfoData: JSON.parse(localStorage.getItem('userData')),
      redirectTo: window.location,
      idWebinar: router.currentRoute.params.id,
      show: false,
    }
  },
  created() {
    this.show = true
    this.$http.post(`https://edu.startupchoikhona.tj/backend/api/bbb-meeting/${this.idWebinar}/start-as-student`, {})
      .then(response => {
        window.location = response.data.data
      })
      .catch(() => {
        this.show = false
      })
  },
}
</script>

<style>

</style>
